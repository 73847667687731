
import { Component, Prop } from "vue-property-decorator";
import { MR } from "@/store/modules";
import { mixins } from "vue-class-component";
import { MRErrorMixin } from "./MRErrorMixin";

@Component
export class MRPaidButton extends mixins(MRErrorMixin) {
  @Prop(MR)
  private record!: MR;

  @Prop(Boolean)
  loading = false;

  savingText = "Saving";

  get isPaid() {
    // return false;
    return this.record.paid;
  }

  get text() {
    return this.record.paid ? "Unpaid" : "Paid";
  }

  onClick() {
    this.$bvModal
      .msgBoxConfirm(`Are you sure you want mark this MR as ${this.text}?`, {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.update());
      });
  }

  async update() {
    // this.loading = true;
    try {
      await MR.dispatch("paid", {
        id: this.record.id,
      });
    } catch (err) {
      console.error("Error occured", err, this);
    }

    // if (!this.hasError()) {
    //   this.$root.$bvToast.toast(`Record has been updated`, {
    //     title: "Saving",
    //     variant: "success",
    //   });
    //   this.loading = false;
    //   return;
    // }

    // this.$root.$bvToast.toast(`Failed to save MR`, {
    //   title: "Error",
    //   variant: "danger",
    // });

    // this.loading = false;
  }
}

export default MRPaidButton;
