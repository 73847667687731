
import { Component, Prop, Vue } from "vue-property-decorator";
import PartSold, { PartSoldInterface } from '../../store/modules/PartSold/PartSold';

@Component
export default class PartSoldWarrantyFlag extends Vue {
  @Prop(PartSold)
  private record!: PartSoldInterface;

  show() {
    return Boolean(Number(this.record.warranty_replacement))
  }

  text() {
    return 'warranty';
  }

  color() {
    return "danger";
  }
}
