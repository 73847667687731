
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin, HeMixin } from "@/mixins";
import { Timesheet } from "@/store/modules";
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';

@Component
export class MRTimeListPrint extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Timesheet[];

  @Prop({ default: () => false })
  private loading!: boolean;
  minLines = 8;
  // items: Array<any> = [];
  // fields: Array<any> = [
  //   { key: "date", label: "Name" },
  //   // { key: "start", label: "Start" },
  //   // { key: "end", label: "End" },
  //   { key: "timeRange", label: "Time" },
  //   { key: "durationHours", label: "Hours" },
  //   { key: "work_type", label: "Type" },
  // ];

  get fields() {
    return [
      { key: "date", label: "Date" },
      { key: "tech_name", label: "Tech" },
      { key: "hours", label: "Hours" },
      { key: "ot", label: "O.T" },
      { key: "rate", label: "Rate" },
    ];
  }

  limit = 25;

  get tableData() {
    const items = (this.items || []).map((item) => ({
      date: this.formatDate("MMM DD YYYY", item.start),
      tech_name: item.tech_name,
      hours: item.durationHours,
      // hours: this.diffAsHours(item.start, item.end),
      ot: "",
      rate: item.rate ? this.formatLocaleCurrency(item.rate) : "",
    })) as Array<any>;
    const m = this.minLines;
    if (items.length < m) {
      const fill = m - items.length;
      for (let i = 0; i < fill; i++) {
        items.push({});
      }
    }
    return items;
    // return [];
  }
  // loading: boolean = true;
}

export default MRTimeListPrint;
