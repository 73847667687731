<template>
  <div class="animated fade-in printable-document">
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col class="clearfix">
            <img
              width="250"
              alt="Logo"
              src="img/brand/sf-logo.png"
              class="float-left"
            />
            <!-- <h1 class="display-5 font-weight-lighter text-right py-1 px-3">
          <span>{{title}}</span>
            </h1>-->
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <span class="font-weight-bold text-sf pr-1">P</span>(403) 328-0337
            <span class="font-weight-bold text-sf pl-2 pr-1">E</span
            >office@kbheating.com
            <span class="font-weight-bold text-sf pl-2 pr-1">F</span>(403)
            328-0053
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col> 3569 32nd Avenue North Lethbridge, AB T1H7C2 </b-col>
        </b-row>
        <slot name="bottom-section"></slot>
      </b-col>
      <b-col>
        <slot name="meta-info"></slot>
      </b-col>
    </b-row>

    <slot></slot>
  </div>
</template>

<script>
import Vue from "vue";
// import Component from "vue-class-component";
import { Prop, Component } from "vue-property-decorator";

@Component
export default class PrintDocument extends Vue {
  @Prop([String])
  title;
}
</script>

<style lang="scss">
$blue-color: #0055a5;
.printable-document {
  padding: 0.5cm;
  position: relative;
  height: auto;
  width: 21.59cm;
  background-color: #fff;
  font-size: 12px;
  border-color: $blue-color;
}
.printable-document h1 {
  font-size: 2.4em;
}
.printable-document table {
  // border-color: rgba(0, 0, 0, 0.2);

  th {
    color: $blue-color;
  }
}

.text-blue {
  color: $blue-color;
}

.print-tables {
  // border: 1px solid #000;
  > tr,
  > tbody > tr,
  > thead > tr {
    > td,
    th {
      vertical-align: middle;
      border: 1px solid $blue-color;
      height: 25px;
    }
  }
}
/* body */

@page {
  size: A4;
  margin: 0;
}

@media print {
  @page {
    margin-top: 42px;
  }

  /* html,
  body {
    width: 210mm;
    height: 297mm;
  } */
  body,
  h1,
  h2,
  h3 {
    width: auto;
    border: 0;
    margin: 0 5%;
    padding: 0;
    float: none;
    position: static;
    overflow: visible;
    background: none;
  }

  .printable-document {
    width: 100%;
  }

  .c-sidebar {
    display: none;
  }
}
</style>