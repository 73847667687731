
import { Component, Vue, Prop } from "vue-property-decorator";
// import VueSignaturePad from "vue-signature-pad";
import { BFormDatepicker } from "bootstrap-vue";
import { signatureRepository } from "@/store/modules/Signature/repository";
import { MR } from "@/store/modules";

@Component({
  components: { /*VueSignaturePad,*/ BFormDatepicker }
})
export class MRSignature extends Vue {
  $refs!: {
    // signaturePad: VueSignaturePad;
  };

  signatureDate: Date = new Date();

  @Prop(MR)
  private record!: MR;

  img: null | string = null;

  // data() {
  //   return {
  //     img: null
  //   };
  // }

  onBegin() {
    // console.log("onBegin", this.$refs.signaturePad);
  }

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  clear() {
    // this.$refs.signaturePad.clearSignature();
  }

  async fetchRender() {
    if (!this.record.signatureID) {
      return null;
    }

    try {
      const response = await signatureRepository.downloadSvg(
        this.record.signatureID
      );

      return (new Promise((ok, fail) => {
        const blob = new Blob([response.data], { type: "image/svg" });

        // console.log("signature", blob);
        // const a = new FileReader();
        // a.readAsDataURL(blob);
        // a.onload = ok;
        this.img = window.URL.createObjectURL(blob);
        ok(this.img);
        // console.log(window.URL.createObjectURL(blob));
      }))/*.then((data) => {
      });*/
      
    } catch (err) {
      console.error("No signature", err);
      this.img = null;
    }

    // const dataUri = Buffer.from(response.data, "binary").toString("base64");
  }
}

export default MRSignature;
