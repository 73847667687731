
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR, PartRequest } from "@/store/modules";
import { HeMixin, DateFormatMixin } from "@/mixins";
import PartRequestList from "@/pages/part-request/PartRequestList.vue";
import { TableCardLoader } from "@/components/TableCardLoader.vue";

@Component({
  components: { PartRequestList, TableCardLoader },
})
export class MRPartRequest extends mixins(HeMixin, DateFormatMixin) {
  @Prop(MR)
  private record!: MR;
  loading = true;

  private partRequest?: PartRequest[];

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    this.loading = true;
    await PartRequest.dispatch("findByMR", {
      recordId: this.record.id,
      sort: 'created_at',
      order: 'desc'
    });

    // this.partRequest = this.findRecord();
    this.loading = false;
  }

  get items(): PartRequest[] {
    return (
      PartRequest.query()
        .withAllRecursive()
        .where("mr_id", +this.record.id)
        .get() || []
    );
  }
}

export default MRPartRequest;
