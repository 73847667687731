import Vue from 'vue'
import Component from 'vue-class-component'
import { CurrencyFormatMixin } from "@/mixins";
import { MR, MRInterface, Task, Payment } from "@/store/modules";


@Component
export class MRSummaryMixin extends CurrencyFormatMixin {
  calcTotalPayment(item: MR) {
    if (!item) {
      return 0;
    }

    if (!item.payments || !item.payments.length) {
      return 0;
    }

    const t = item.payments
      .filter((p) => !p.voided)
      .map((payment) => payment.amount || 0)
      .reduce((a, b) => a + b, 0);

    if (!t || isNaN(t)) {
      return 0;
    }

    return t;
  }

  calcTotalApprovePayment(item: MR) {
    if (!item) {
      return 0;
    }

    if (!item.payments || !item.payments.length) {
      return 0;
    }

    const t = item.payments
      .filter((p) => p.approved && !p.voided)
      .map((payment) => payment.amount || 0)
      .reduce((a, b) => a + b, 0);

    if (!t || isNaN(t)) {
      return 0;
    }

    return t;
  }
}
