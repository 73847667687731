
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { TaskCardInfo } from "../task/TaskCardInfo.vue";
import { MR } from "@/store/modules";
import { DateFormatMixin, HeMixin } from "@/mixins";

@Component({
  components: { TaskCardInfo }
})
export class MRCardInfo extends mixins(DateFormatMixin, HeMixin) {
  @Prop({ default: () => null })
  private record!: MR | null;
}

export default MRCardInfo;
