
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BulletListLoader } from "vue-content-loader";
import { MRCardInfo } from "./MRCardInfo.vue";
import { MRTimeList } from "./MRTimeList.vue";
import { JobCardInfo } from "../job/JobCardInfo.vue";
import {
  MRApproveButton,
  MRVoidButton,
  MRCompleteButton,
  MRPaidButton,
  MRJobInfo,
  MRSignature,
  MRSummary,
  MRNotes,
  MRStatusCallout,
  MRPartSold,
  MRPartRequest,
} from "./detail";
import { PaymentList } from "@/pages/payment/PaymentList.vue";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { MR, MRInterface, Task, Payment } from "@/store/modules";
import { MRSummaryMixin } from "./mixin/MRSummaryMixin";
import { mapState } from "vuex";
import { ErrorInterface } from "@/utility";
// import MRNotes from './detail/MRNotes.vue';
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';
// import { MRJobInfo } from "./detail/MRJobInfo.vue";
// import { MRSignature } from "./detail/MRSignature.vue";
// import { MRSummary } from "./detail/MRSummary.vue";

@Component({
  // computed: {
  //   ...mapState("entities/mr", {
  //     item() {
  //       MR.query().withAllRecursive().whereId(this.id).first();
  //     },
  //   }),
  // },
  components: {
    MRJobInfo,
    MRCardInfo,
    MRTimeList,
    JobCardInfo,
    BulletListLoader,
    MRSignature,
    MRSummary,
    MRApproveButton,
    MRVoidButton,
    MRCompleteButton,
    MRPaidButton,
    PaymentList,
    MRNotes,
    MRStatusCallout,
    MRPartSold,
    MRPartRequest,
  },
})
export class MRDetail extends mixins(HeMixin, MRSummaryMixin) {
  @Prop([String, Number])
  readonly id!: string | number;

  // @Prop({default: () => true})
  private loading = true;

  private saving = false;

  private item: MR | null = null;

  private subscription: Array<() => void> = [];

  destroyed() {
    this.subscription.forEach((fn) => fn());
  }

  created() {
    const update = this.$store.watch<ErrorInterface[]>(
      () => MR.getErrors(),
      (val, oldVal) => {
        if (val && val.length) {
          this.$root.$bvToast.toast(`Failed to save Payment`, {
            title: "Error",
            variant: "danger",
          });
        }
      }
    );
    const saving = this.$store.watch<boolean>(
      () => {
        return MR.isSaving();
      },
      (val) => {
        this.saving = val;
      }
    );

    const fetch = this.$store.watch<MR>(
      () => MR.getters("find")(this.id),
      () => {
        // console.log("B", newMr, oldMr);
        this.item = this.findRecord();
      }
    );
    this.subscription = [fetch, update, saving];
  }

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  async fetchRender() {
    // const existing = MR.find(this.id);

    // if (existing) {
    //   this.update({ loading: false });
    //   return;
    // }
    // console.log("test", this.id, existing);

    await MR.dispatch("findOne", { id: +this.id });
    await Payment.dispatch("findByMR", {
      recordId: +this.id,
      sort: 'created_at',
      order: 'desc'
    });

    this.$nextTick(() => {
      this.update({ loading: false });
    });
  }

  print() {
    // console.log(this.$htmlToPaper);
    if (this.item) {
      // this.$htmlToPaper(`mr-main-container-${this.item.id}`);
      window.print();
    }
  }

  update(data: { loading: boolean }) {
    this.loading = data.loading;
    this.item = this.findRecord();
    // if (this.item && 'taskID' in this.item) {
    //   console.log(
    //     Task.query()
    //       .withAll()
    //       .whereId(+this.item['taskID'])
    //       .first()
    //   );
    // }
    console.log("update", this.item);
  }

  get fetching(): boolean {
    if (this.loading) {
      return this.loading;
    }
    return !!MR.store().state.entities.mr.fetching;
  }

  get totalApprovedPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalApprovePayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalPayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalRemaining() {
    if (!this.item) {
      return 0;
    }
    const t = this.calcTotalApprovePayment(this.item);
    return this.formatLocaleCurrency((this.item.price || 0) * 1.05 - t);
  }

  findRecord(): MR | null {
    return MR.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  // @Watch('record')
  // onRecordChange() {

  // }

  // getItem() {
  //   return this.$store.getters
  // }
}

export default MRDetail;
