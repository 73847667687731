
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import { Timesheet } from "@/store/modules";
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';

@Component
export class MRTimeList extends mixins(DateFormatMixin, HeMixin) {
  @Prop({ default: () => [] })
  private items!: Timesheet[];

  @Prop({ default: () => false })
  private loading!: boolean;

  // items: Array<any> = [];
  fields: Array<any> = [
    { key: "tech_name", label: "Name" },
    // { key: "start", label: "Start" },
    // { key: "end", label: "End" },
    { key: "timeRange", label: "Time" },
    { key: "durationHours", label: "Hours" },
    { key: "work_type", label: "Type" },
    { key: "voided", label: "Voided" },
  ];

  limit = 25;
  // loading: boolean = true;
}

export default MRTimeList;
