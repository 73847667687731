
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR } from "@/store/modules";
import { CurrencyFormatMixin } from "@/mixins";
import { MRSummaryMixin } from '../mixin/MRSummaryMixin';

@Component({
  components: {}
})
export class MRSummary extends mixins(MRSummaryMixin) {
  @Prop(MR)
  private record!: MR;

  get total(): string {
    // return '0';
    return this.formatLocaleCurrency(this.record.price ? (this.record.price * 1.05) : 0);
  }

  formatHours(record: MR) {
    const h = +(record.hours || 0);
    if(isNaN(h)) {
      return 0;
    }
    // api stores this as 15 minute intervals
    return h / 4;
  }

  get totalRemaining() {
    if (!this.record) {
      return 0;
    }
    const t = this.calcTotalApprovePayment(this.record);
    return this.formatLocaleCurrency((this.record.price || 0) * 1.05 - t);
  }
}

export default MRSummary;
