
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR, PartSold, PartSoldInterface, Part } from "@/store/modules";
import { HeMixin, DateFormatMixin, CurrencyFormatMixin } from "@/mixins";
import PartSoldList from "@/pages/part-sold/PartSoldList.vue";
import { TableCardLoader } from "@/components/TableCardLoader.vue";
import { partition } from "rxjs/operators";

@Component({
  components: { PartSoldList, TableCardLoader },
})
export class MRPartSoldPrint extends mixins(HeMixin, DateFormatMixin, CurrencyFormatMixin) {
  @Prop(MR)
  private record!: MR;
  loading = true;

  private partSold?: PartSold[];

  minLines = 10;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  get items() {
    const items = (this.partSold || []).map((item) => ({
      quantity: item.quantity,
      part: item.part,
      cost: item.cost,
    })) as Array<any>;
    const m = this.minLines;
    if (items.length < m) {
      const fill = m - items.length;
      for (let i = 0; i < fill; i++) {
        items.push({});
      }
    }
    return items;
  }

  get fields() {
    return [
      {
        key: "quantity",
        label: "Quant.",
      },
      {
        key: "part",
        label: "Part # and Desc",
        formatter: (part: Part) => {
          if (!part || (!part.name && !part.part_number)) {
            return "";
          }
          return `${part.name} [${part.part_number}]`;
        },
      },
      {
        key: "cost",
        label: "Amount",
        formatter: (val: number | null) => {

          return val ? this.formatLocaleCurrency(val) : '';
        }
      },
    ];
  }

  async updateData() {
    this.loading = true;
    await PartSold.dispatch("findByMR", { recordId: this.record.id });

    this.partSold = this.findRecord();

    this.loading = false;
  }

  findRecord(): PartSold[] {
    return (
      PartSold.query()
        .withAllRecursive()
        .where("mr_id", +this.record.id)
        .get() || []
    );
  }
}

export default MRPartSoldPrint;
