
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR, PartSold } from "@/store/modules";
import { HeMixin, DateFormatMixin } from "@/mixins";
import PartSoldList from "@/pages/part-sold/PartSoldList.vue";
import { TableCardLoader } from "@/components/TableCardLoader.vue";

@Component({
  components: { PartSoldList, TableCardLoader },
})
export class MRPartSold extends mixins(HeMixin, DateFormatMixin) {
  @Prop(MR)
  private record!: MR;
  loading = true;

  private partSold?: PartSold[];

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    this.loading = true;
    await PartSold.dispatch("findByMR", {
      recordId: this.record.id,
      sort: 'created_at',
      order: 'desc'
    });

    this.partSold = this.findRecord();

    this.loading = false;
  }

  findRecord(): PartSold[] {
    return (
      PartSold.query()
        .withAllRecursive()
        .where("mr_id", +this.record.id)
        .get() || []
    );
  }
}

export default MRPartSold;
