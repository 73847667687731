
import { Component } from "vue-property-decorator";
import MRList from "./MRList.vue";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import { MR } from "@/store/modules";
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';

@Component({
  components: { MRList }
})
export default class MRIndex extends mixins(DateFormatMixin, HeMixin) {
  limit = 25;
  // loading: boolean = true;

  /*created() {
    console.log("created");
  }

  mounted() {
    console.log("mounted");
    // this.$nextTick(() => this.fetchRender());
  }

  updated() {
    console.log("updated 2");
  }

  beforeDestroy() {
    console.log("beforeDestroy");
  }

  destroyed() {
    console.log("destroyed");
  }

  fetchRender() {
    MR.dispatch("fetchData");
  }*/

  /*get items(): Array<any> {
    const data = MR.query()
      .withAll()
      .get();
    return data;
  }

  get loading(): boolean {
    return !!MR.store().state.entities.mr.fetching;
  }*/

  /*get pagination(): boolean | AnyObject {
    const total = MR.store().state.entities.mr.total;
    if(total) {
      console.log('test', Math.min(Math.ceil(total / this.limit), 1) );
      return {
        pages: Math.min(Math.ceil(total / this.limit), 1),
        activePage: 2
      };
    }
    return false;
  }*/
}
