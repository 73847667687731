
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BulletListLoader } from "vue-content-loader";
import { MRCardInfo } from "./MRCardInfo.vue";
import { MRTimeListPrint } from "./MRTimeListPrint.vue";
import { JobCardInfo } from "../job/JobCardInfo.vue";
import {
  MRApproveButton,
  MRVoidButton,
  MRCompleteButton,
  MRPaidButton,
  MRJobInfo,
  MRSignaturePrint,
  MRSummary,
  MRNotes,
  MRStatusCallout,
  MRPartSold,
  MRPartRequest,
  MRPartSoldPrint,
  MRSummaryPrint,
} from "./detail";
import { PaymentList } from "@/pages/payment/PaymentList.vue";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { MR, MRInterface, Task, Payment, Job } from "@/store/modules";
import { MRSummaryMixin } from "./mixin/MRSummaryMixin";
import { mapState } from "vuex";
import PrintDocument from "@/components/shared/PrintDocument.vue";
// import MRNotes from './detail/MRNotes.vue';
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';
// import { MRJobInfo } from "./detail/MRJobInfo.vue";
// import { MRSignaturePrint } from "./detail/MRSignaturePrint.vue";
// import { MRSummary } from "./detail/MRSummary.vue";

@Component({
  // computed: {
  //   ...mapState("entities/mr", {
  //     item() {
  //       MR.query().withAllRecursive().whereId(this.id).first();
  //     },
  //   }),
  // },
  components: {
    MRJobInfo,
    MRCardInfo,
    MRTimeListPrint,
    JobCardInfo,
    BulletListLoader,
    MRSignaturePrint,
    MRSummary,
    MRApproveButton,
    MRVoidButton,
    MRCompleteButton,
    MRPaidButton,
    PaymentList,
    MRNotes,
    MRStatusCallout,
    MRPartSold,
    MRPartRequest,
    PrintDocument,
    MRPartSoldPrint,
    MRSummaryPrint,
  },
})
export class MRPrint extends mixins(
  HeMixin,
  DateFormatMixin,
  MRSummaryMixin,
  CurrencyFormatMixin
) {
  @Prop([String, Number])
  readonly id!: string | number;

  // @Prop({default: () => true})
  private loading = true;

  private item: MR | null = null;

  private job: Job | null = null;

  private compiledNotesLength = 0;
  private compiledNotes: string[] = [];

  mounted() {
    // this.$store.watch<MR>(
    //   () => MR.getters("find")(this.id),
    //   () => {
    //     // console.log("B", newMr, oldMr);
    //     this.item = this.findRecord();
    //   }
    // );
    this.$nextTick(() => this.fetchRender());
  }

  async fetchRender() {
    // const existing = MR.find(this.id);

    // if (existing) {
    //   this.update({ loading: false });
    //   return;
    // }
    // console.log("test", this.id, existing);

    await MR.dispatch("findOne", { id: +this.id });
    await Payment.dispatch("findByMR", {
      recordId: +this.id,
    });

    const record = this.findRecord() as MRInterface | null;
    if (record && record.task && record.task.jobID) {
      await Job.dispatch("findOne", {
        id: +record.task.jobID,
        withCustomer: true,
        withAddress: true,
        withBillingAddress: true,
      });
    }
    this.$nextTick(() => {
      this.update({ loading: false });
    });
  }

  truncateNotes(offset = 0, maxLength = 500) {
    const data = this.compiledNotes;
    let len = 0, last = 0;

    const c: string[] = [];

    for (let i = 0; i < data.length; i++) {
      const txt = data[i];
      len += txt.length;
      if (len < maxLength) {
        c.push(txt);
        last = len;
        continue;
      }
      // console.log('Truncate needed', maxLength - last, txt.substring(0, maxLength - last))
      c.push(txt.substring(0, maxLength - last - 3) + '...');
      c.push('<em>See next page for full notes</em>')
      return c;
    }

    return c;
  }

  get notes() {
    return this.compiledNotes;
  }

  compileNotes(): string[] {
    const item = this.item as MRInterface;
    if (!item) {
      return [""];
    }

    let data = [];
    if (item.task && item.task.description) {
      let desc = `${this.htmlDecode(item.task.description)}`.trim();
      data.push(desc);
    }

    if (item.notes && item.notes.length) {
      data = item.notes.reduce((acc, note) => {
        if (note) {
          const n = `${this.htmlDecode(note.note)}`.trim();
          acc.push(n);
        }

        return acc;
      }, data);
    }


    return data;
  }

  // print() {
  //   // console.log(this.$htmlToPaper);
  //   if (this.item) {
  //     // this.$htmlToPaper(`mr-main-container-${this.item.id}`);
  //     window.print();
  //   }
  // }

  update(data: { loading: boolean }) {
    this.loading = data.loading;
    this.item = this.findRecord();
    const record = this.item as MRInterface | null;
    if (record && record.task && record.task.job) {
      this.job = record.task.job;
    }

    const notes = this.compileNotes();
    this.compiledNotesLength = notes.reduce((acc, txt) => (acc += txt.length, acc), 0)
    this.compiledNotes = notes;
    // if (this.item && 'taskID' in this.item) {
    //   console.log(
    //     Task.query()
    //       .withAll()
    //       .whereId(+this.item['taskID'])
    //       .first()
    //   );
    // }
    // console.log("update-print", this.item);
  }

  get fetching(): boolean {
    if (this.loading) {
      return this.loading;
    }
    return !!MR.store().state.entities.mr.fetching;
  }

  get totalApprovedPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalApprovePayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalPayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalRemaining() {
    if (!this.item) {
      return 0;
    }
    const t = this.calcTotalApprovePayment(this.item);
    return this.formatLocaleCurrency((this.item.price || 0) * 1.05 - t);
  }

  get mrId() {
    if (!this.item) {
      return "";
    }
    return `${this.item.id}`.padStart(6, "0");
  }

  findRecord(): MR | null {
    return MR.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  // @Watch('record')
  // onRecordChange() {

  // }

  // getItem() {
  //   return this.$store.getters
  // }
}

export default MRPrint;
