
import { Component, Vue, Prop } from "vue-property-decorator";
import {InfoCardLoader} from '@/components/InfoCardLoader.vue';
// import { ContentLoader } from 'vue-content-loader'
// import JobCardInfo from '@/pages/job/JobCardInfo.vue';
import { JobCardInfo } from "@/pages";
import { Job, MR, MRInterface } from "@/store/modules";

@Component({
  components: { JobCardInfo, InfoCardLoader }
})
export class MRJobInfo extends Vue {
  @Prop(MR)
  private record!: MRInterface;

  private job: Job | null = null;

  private loading: boolean = true;

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  async fetchRender() {
    const { task } = this.record;

    if (task && task.jobID) {
      const existingJob = this.findJob(+task.jobID);
      if (existingJob) {
        this.job = existingJob;
        this.loading = false;
        return;
      }

      this.loading = true;

      await Job.dispatch("findOne", {
        id: +task.jobID,
        withCustomer: true,
        withAddress: true,
        withBillingAddress: true
      });

      const job = this.findJob(+task.jobID);
      if (job) {
        this.job = job;
        this.loading = false;
        return;
      }
    }
  }

  findJob(id: number, related: boolean = true): Job | null {
    if (!related) {
      return Job.find(id);
    }
    return Job.query()
      .withAllRecursive()
      .whereId(id)
      .first();
  }
}

export default MRJobInfo;
