
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { PartSold, PartSoldFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from '@/@types';
import PartSoldWarrantyFlag from './PartSoldWarrantyFlag.vue'

@Component({
  components: { PartSoldWarrantyFlag }
})
export class PartSoldList extends mixins(DateFormatMixin, HeMixin, CurrencyFormatMixin) {
  @Prop({ default: () => [] })
  private items!: PartSold[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => false })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: PartSoldFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "warranty_replacement", label: "", filter : false, sorter: false  },
    { key: "part_id", label: "Part", filter: false, sorter: false },
    { key: "tech_name", label: "Tech", filter : false, sorter: false },
    { key: "quantity", label: "Qty", filter : false, sorter: false },
    { key: "cost", label: "Cost", filter : false, sorter: false  },
    { key: "created_at", label: "On", filter : false, sorter: false  },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: PartSoldFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: PartSold) {
    // this.$router.push(`/partSold/${item.id}`);
  }
}

export default PartSoldList;
